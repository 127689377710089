@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.App {
  text-align: center;
}
p {
  margin: 0px;
}

* {
  /* font-family: sans-serif; */
  font-family: "Poppins", "Roboto", "Helvetica", "Arial";
  /* font-family: 'Courier New', Courier, monospace; */
}

#navBar {
  position: relative;
  height: 140px;
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;

  background-color: #151F40;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  font-size: 32px;
  text-align: center;

  box-shadow: 0px 5px 8px rgba(0, 0, 0, .4);
}

/* #navBar img {
  align-items: center;
  align-content: center;
  height: 100px;
  width: 100px;
  margin-top: 55px;
  margin-right: 20px;
} */

#logo {
  padding: 5px;
  max-height: 120px;
}

#logo img {
  max-width: 90vw;
}



#number {
  position: absolute;
  right: 10px;
  /* float: right; */
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */

  margin: 0px;

}

/* the quote div */
#quote {
  height: auto;
  z-index: -1;
  width: 100%;
  background-color: #151F40;

  box-shadow: 0px 5px 5px rgba(0, 0, 0, .7);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: white;

  font-size: 16px;
}

/* the button */
#contact {
  color: white;
  margin: 10px;
  padding: 2px 25px 2px 25px;
  background-color: transparent;

  border: 1px solid white;
  border-radius: 3px;
  cursor: pointer;
}

#contact:hover {
  background-color: white;
  color: black;
  transition: .3s ease-in-out;
}

.gallery {
  width: 100%;
  height: 100%;
  /* min-height: "500px"; */
  /* max-height: "700px"; */
  max-height: 100%;
  max-width: 100%;
  /* object-fit: fill; */
  object-fit: cover;
  /* position: absolute; */
  z-index: -1;
  opacity: .7;
  background-size: cover;
  box-shadow: 0px 5px 12px rgba(0,0,0,.7);
}

#header {
  background-color: black;
  position: relative;
  text-align: center;
  color: white;
  height: 500px;
  width: 100%;
}

#header #text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imageTile {
  width: 100%;
  height: 100%;

  max-height: 100%;
  max-width: 100%;
  
  object-fit: cover;
  background-size: cover;
}

#location {
  display: flex;
  flex-wrap: wrap;
  font-weight: bolder;

  justify-content: center;
  align-items: center;

  font-size: 14px;
  padding-top: 10px;
}

.panel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel h5 {
  font-size: larger;
  padding: 20px;
  text-align: center;
}

.panel h4 {
  font-size: larger;
  padding: 20px;
  text-align: center;
}

@media (max-width: 975px) {
  #logo {
    padding-bottom: 0px;
  }

  #navBar {
    flex-wrap: wrap;
    flex-direction: row;
  
    height: fit-content;
    width: 100%;

  }

  #number { 
    position: relative;
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  #title {
    padding-left: 10px;
    /* padding-right: 50px; */
    font-size: 36px;
    margin-top: 35px;
  }

  #links {
    padding-right: 200px;
    margin-top: -105px;
    margin-left: 245px;
  }

  #quote p {
    padding-top: 5px;
  }
}



@media (min-width: 1400px) {
  #header {
    height: 800px;
  }
}

.whiteLink {
  font-family: "Poppins", "Roboto", "Helvetica", "Arial";
  color: white;
  /* text-decoration: none; */
}

.blackLink {
  font-family: "Poppins", "Roboto", "Helvetica", "Arial";
  color: black;
  /* text-decoration: none; */
}

.slider {
    width: 100%;
    max-height: 600px;
    /* max-width: 800px; */
    text-align: center;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    
}